<template>
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #content>
          <DataTable
            :value="reservas_cartao"
            class="p-datatable-sm"
            :paginator="true"
            :rows="5"
            stripedRows
            :loading="loading"
            dataKey="id"
            :filters.sync="filtros"
            filterDisplay="menu"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="rowsPerPageOptions"
            currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} reservas de cartão"
            responsiveLayout="scroll">
            <template #empty> Nenhuma reserva de cartão encontrada. </template>
            <template #loading> Carregando. Por favor aguarde. </template>
            <Column header="Cadastrado Em">
              <template #body="{ data }">
                {{ formatarData(data.auditMetadata.cadastradoEm) }}
              </template>
            </Column>
            <Column header="Cadastrado Por">
              <template #body="{ data }">
                {{ data.cadastradoPor ? data.cadastradoPor : ' - ' }}
              </template>
            </Column>
            <Column header="Cancelado Em">
              <template #body="{ data }">
                {{ formatarData(data.dataCancelamento) }}
              </template>
            </Column>
            <Column header="Cancelado Por">
              <template #body="{ data }">
                {{ data.canceladoPor ? data.canceladoPor : ' - ' }}
              </template>
            </Column>
            <Column
              field="consignataria.nomeFantasia"
              header="Consignatária"></Column>
            <Column field="valor" header="Valor">
              <template #body="{ data }">
                <div class="flex justify-content-end flex-wrap card-container">
                  <div class="flex align-items-center justify-content-center">
                    {{ data.valor | formatarValor }}
                  </div>
                </div>
              </template>
            </Column>
            <Column header="Status">
              <template #body="slotProps">
                <Chip
                  :label="labelChip(slotProps.data.codigoStatusReservaCartao)"
                  :class="
                    classChip(slotProps.data.codigoStatusReservaCartao)
                  " />
              </template>
            </Column>
            <Column header="Ações">
              <template #body="{ data }">
                <Button
                  v-if="exibirBotaoAtivar(data.codigoStatusReservaCartao)"
                  v-tooltip.left="'Ativar'"
                  icon="pi pi-check"
                  class="mt-2 mr-2 p-button-rounded p-button-success"
                  @click="confirmarAcao(data, 'ativar')" />
                <Button
                  v-if="
                    exibirBotaoSuspenderOuCancelar(
                      data.codigoStatusReservaCartao,
                    )
                  "
                  v-tooltip.left="'Cancelar/Suspender'"
                  icon="pi pi-times"
                  class="mt-2 mr-2 p-button-rounded p-button-danger"
                  @click="confirmarAcao(data, 'cancelar')" />
                <Button
                  v-tooltip.left="'Histórico'"
                  icon="pi pi-list"
                  class="mt-2 mr-2 p-button-rounded p-button"
                  @click="exibirHistorico(data)" />
              </template>
            </Column>
          </DataTable>
        </template>
      </Card>
      <modal-senha-servidor
        :exibir="exibirModalSenhaUsuarioLogado"
        :metodoSalvar="realizarAcao"
        :tipo="false"
        @mudarVisibilidade="exibirModalSenhaUsuarioLogado = $event">
      </modal-senha-servidor>
      <Dialog
        :visible.sync="modalHistorico"
        :containerStyle="{ width: '50vw' }">
        <DataTable
          class="p-datatable-sm"
          :paginator="true"
          :rows="5"
          stripedRows
          :loading="loading"
          :value="historico"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="rowsPerPageOptions"
          currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} consignações"
          responsiveLayout="scroll">
          <template #header>Histórico</template>
          <template #empty> Nenhum histórico encontrado. </template>
          <template #loading> Carregando. Por favor aguarde. </template>
          <Column header="Alterado Em">
            <template #body="{ data }">
              {{ formatarData(data.auditMetadata.alteradoEm) }}
            </template>
          </Column>
          <Column header="Alterado Por">
            <template #body="{ data }">
              {{ data.alteradoPor }}
            </template>
          </Column>
          <Column header="Status">
            <template #body="{ data }">
              {{ data.tipo }}
            </template>
          </Column>
          <Column header="Ações">
            <template #body="{ data }">
              <Button
                v-if="exibirBotaoDetalhe(data.tipo)"
                v-tooltip.left="'Detalhes'"
                icon="pi pi-eye"
                class="mt-2 mr-2 p-button-rounded p-button-success"
                @click="detalheReservaCartao(data)" />
            </template>
          </Column>
        </DataTable>
      </Dialog>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api/'
import ReservaCartaoService from '@/service/ReservaCartaoService.js'
import { consignatariaStore } from '@/stores/consignataria'
import { servidorStore } from '@/stores/servidor'
import HistoricoConsignacao from '@/domain/HistoricoConsignacao.js'
import auth from '@/modules/auth'
import ModalSenhaServidor from '@/components/shared/modal/modalSenhaServidor.vue'
import HistoricoConsignacaoService from '@/service/HistoricoConsignacaoService.js'

export default {
  components: {
    'modal-senha-servidor': ModalSenhaServidor,
  },

  setup() {
    const store = consignatariaStore()
    const storeServidor = servidorStore()
    return { store, storeServidor }
  },

  data() {
    return {
      reservas_cartao: [],
      loading: false,
      totalRecords: 0,
      historicoConsignacao: new HistoricoConsignacao(),
      filtros: {},
      idMatricula: this.$route.params.id,
      auth,
      exibirModalSenhaUsuarioLogado: false,
      idReservarCartao: null,
      acao: null,
      modalHistorico: false,
      historico: [],
      dadosReservaSelecionada: null,
    }
  },

  computed: {
    rowsPerPageOptions() {
      return [5, 10, 25]
    },
  },

  created() {
    this.reservaCartaoService = new ReservaCartaoService(this.$http)
    this.historicoConsignacaoService = new HistoricoConsignacaoService(
      this.$http,
    )
  },

  mounted() {
    this.carregarListaReservarCartao()
  },

  methods: {
    carregarListaReservarCartao() {
      this.loading = true
      const consignataria = JSON.parse(
        localStorage.getItem('consignatariaSelecionada'),
      )

      this.reservaCartaoService
        .getListaReservaCartaoPorConsignatariaSelecionada(
          this.idMatricula,
          consignataria.id,
        )
        .then((res) => {
          this.reservas_cartao = res
          this.loading = false
          this.exibirModalSenhaUsuarioLogado = false
        })
        .catch((err) => {
          this.loading = false
          this.$toast.add({
            severity: 'error',
            summary: err.response.data.message,
            life: 10000,
          })
        })
    },

    carregaHistoricoReservaCartao(reservaCartaoId) {
      this.historicoConsignacaoService
        .getHistoricoConsignacaoByReservaCartao(reservaCartaoId)
        .then((res) => {
          this.historico = res
        })
        .catch((err) => {
          this.exibirModalSenhaUsuarioLogado = false
          this.$toast.add({
            severity: 'error',
            summary: err.response.data.message,
            life: 10000,
          })
        })
    },

    detalheReservaCartao(data) {
      this.$router.push({
        name: 'reserva_cartao_detalhe',
        params: { idCartao: data.id },
      })
    },

    initFiltros() {
      this.filtros = {
        'statusConsignacao.nome': {
          operator: FilterOperator.OR,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
      }
    },

    limparFiltros() {
      this.initFiltros()
    },

    exibirBotaoAtivar(codigo) {
      return (this.auth.hasRoleConsignatariaAdmin() ||
        this.auth.hasRoleCancelarSuspenderReservaCartao()) &&
        (codigo === 2 || codigo === 3)
        ? true
        : false
    },

    exibirBotaoDetalhe(tipo) {
      return tipo !== 'ATIVA'
    },

    exibirBotaoSuspenderOuCancelar(codigo) {
      return (this.auth.hasRoleConsignatariaAdmin() ||
        this.auth.hasRoleCancelarSuspenderReservaCartao()) &&
        codigo === 1
        ? true
        : false
    },

    confirmarAcao(data, acao) {
      this.idReservarCartao = data.id
      this.acao = acao
      this.dadosReservaSelecionada = data
      if (acao === 'ativar') {
        this.exibirModalSenhaUsuarioLogado = true
      } else {
        this.realizarAcao()
      }
    },

    realizarAcao() {
      this.loading = true
      if ('ativar' === this.acao) {
        this.ativar()
      } else if ('suspender' === this.acao) {
        this.suspender()
      } else if ('cancelar' === this.acao) {
        //this.cancelar()
        this.loading = false
        this.cancelarReservaCartao(this.dadosReservaSelecionada)
      }
    },

    ativar() {
      this.historicoConsignacao.reservaCartao = this.dadosReservaSelecionada
      this.historicoConsignacao.tipo = 'ATIVA'
      this.historicoConsignacao.motivo = 'NENHUM'
      this.historicoConsignacao.observacao = 'ativar'
      this.reservaCartaoService
        .ativarReservarCartao(this.idReservarCartao, this.historicoConsignacao)
        .then(() => {
          this.loading = false
          this.$toast.add({
            severity: 'success',
            summary: 'Reserva de Cartão ativada com sucesso!',
            life: 10000,
          })
          this.carregarListaReservarCartao()
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
          this.exibirModalSenhaUsuarioLogado = false
          this.$toast.add({
            severity: 'error',
            summary: err.response.data.message,
            life: 10000,
          })
          this.carregarListaReservarCartao()
        })
    },

    suspender() {
      this.reservaCartaoService
        .suspenderReservarCartao(this.idReservarCartao)
        .then(() => {
          this.loading = false
          this.$toast.add({
            severity: 'success',
            summary: 'Reserva de Cartão suspensa com sucesso!',
            life: 10000,
          })
          this.carregarListaReservarCartao()
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
          this.exibirModalSenhaUsuarioLogado = false
          this.$toast.add({
            severity: 'error',
            summary: err.response.data.message,
            life: 10000,
          })
        })
    },

    cancelar() {
      this.reservaCartaoService
        .cancelarReservarCartao(this.idReservarCartao)
        .then(() => {
          this.loading = false
          this.$toast.add({
            severity: 'success',
            summary: 'Reserva de Cartão cancelada com sucesso!',
            life: 10000,
          })
          this.carregarListaReservarCartao()
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
          this.exibirModalSenhaUsuarioLogado = false
          this.$toast.add({
            severity: 'error',
            summary: err.response.data.message,
            life: 10000,
          })
        })
    },

    cancelarReservaCartao(data) {
      this.$router.push({
        name: 'reserva_cartao_cancelar',
        params: { idCartao: data.id },
      })
    },

    exibirHistorico(data) {
      this.carregaHistoricoReservaCartao(data.id)
      this.historico = data.historico
      this.modalHistorico = true
    },

    formatarData(value) {
      if (!value) return ''
      return new Date(value).toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
    },

    labelChip(codigo) {
      if (codigo === 1) {
        return 'Ativo'
      } else if (codigo === 2) {
        return 'Suspensa'
      } else if (codigo === 3) {
        return 'Cancelada'
      }
    },

    classChip(codigo) {
      if (codigo === 1) {
        return 'mr-2 mb-2 custom-chip ativa'
      } else if (codigo === 2) {
        return 'mr-2 mb-2 custom-chip suspensa'
      } else if (codigo === 3) {
        return 'mr-2 mb-2 custom-chip cancelada'
      }
    },
  },
}
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.cancelada {
  color: #fff;
  background-color: #dc3545;
  font-weight: bold;
  text-align: center;
}

.ativa {
  color: #fff;
  background-color: #28a745;
  font-weight: bold;
  text-align: center;
}

.suspensa {
  color: #1f2d3d;
  background-color: #c0c0c0;
  font-weight: bold;
  text-align: center;
}
</style>
